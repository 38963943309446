








import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProgressLinear extends Vue {
  @Prop({ type: Boolean, required: true })
  isProgressing = false;
}
